import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const HeaderContainer = styled.header`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999999999;
    position: absolute;
    background: #222222;
    width: 100%;
    top: 0px;
`

export const HeaderLogo = styled.div`
    padding-left: 30px;
`

export const HeaderLink = styled.a`
    display:flex;
    align-items: center;
    text-decoration: none;
`

export const HeaderImage = styled.img`
    width: 100px;

    @media screen and (min-width: 2000px)  {
        width: 125px;
    }

`

export const HeaderInfo = styled.div`
    display:flex;
    flex-direction: row;
    height: 50px;
    padding-right: 25px;

    @media screen and (min-width: 2000px)  {
        height: 65px;
    }
`
export const InfoBox = styled.div`
    display:flex;
    flex-direction: row;
    align-items:center;
    background: ${COLORS.box};
    margin-left: 20px;
    padding: 0px 20px;
    border-radius: 7px;
    justify-content: space-between;
    font-weight: 500;

    @media screen and (max-width: 768px)  {
        display:none;
    }
`

export const InfoIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 15px;
`

export const InfoIconArrow = styled.img`
    width: 10px;
    height: 10px;
    margin: 0px 5px;

    @media screen and (min-width: 2000px)  {
        width: 20px;
        height: 20px;
    }
`

export const InfoText = styled.p`
    color: ${COLORS.text};

    @media screen and (min-width: 2000px)  {
        font-size: 24px;
    }
`

export const InfoTextLogout = styled(InfoText)`
    color: ${COLORS.text};
    margin-left: 30px;
    font-weight: 700;
    cursor: pointer;

    @media screen and (min-width: 2000px)  {
        font-size: 24px;
    }
`

export const InfoTextArrows = styled.p`
    color: ${COLORS.text};

    @media screen and (min-width: 2000px) {
        font-size: 24px;
    }
`

export const LogoText = styled.p`
    color: ${COLORS.text};
    font-weight: 500;
    font-size: 19px;
    margin-left: 20px;

    @media only screen and (min-width: 2000px) {
        font-size: 30px;
    }
`
