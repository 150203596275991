import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { setToken, getToken } from '../../utils'

import {
    ReportContainer, BackButton
} from './styles'

import Header from "../Header"

export default function Report() {
    const navigate = useNavigate();
    let token = getToken()

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    }, [token])


    return (
        <>
            <Header />
            <ReportContainer>
                <embed src={"/reports/report-americanas.pdf" + "#toolbar=0&zoom=200"} type="application/pdf"/>
                <BackButton href="/dashboard">Dashboard</BackButton>
            </ReportContainer>

        </>

    )
}
