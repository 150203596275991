
import React, { Component, useEffect } from 'react';
import Header from "../Header"
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils'

import { BackButton, AsideButton } from './styles'

export default function Dashboard() {


    let token = getToken()
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) { navigate('/') }
    }, [token])

    return (
        <>
            <Header />
            <div className='iframes'>
                <iframe className="active" width="100%"
                    src="https://dash.vtracker.com.br/dash/geral/custom_view.html?nome=Teste%20Americanas&sigla=SISTEMA2&view=dash_custom_290_f99e203948da4713ad3b263c52fde89e&refresh=30" />

                <AsideButton>
                    <BackButton href="/report">
                        <img src="/images/report-icon.png" />
                        Report Semanal
                    </BackButton>
                </AsideButton>

            </div>
        </>

    )

}


