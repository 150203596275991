import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const LoginContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #2b2b2b;

`
export const LoginBox = styled.div`
    width: 300px;
    background: #222222;
    border-radius: 12px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
`

export const LoginImage = styled.img`
    width: 200px;

`
export const LoginTitle = styled.h1`
    color: ${COLORS.text};
    font-weight: 500;
    font-size: 2rem;
    margin: 0px;
    margin-bottom: 30px;
`


export const LoginLabel = styled.label`
    font-size: 0.9rem;
    font-weight: 00;
    color: #fff;
    margin-bottom: 5px;
`

export const LoginGroup = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
`
export const LoginError = styled.p`
    font-size: 1rem;
    color: red;
`

export const LoginInput = styled.input`
    height: 40px;
    border-radius: 4px;
    border:none;
    font-size: 1rem;
    padding-left: 10px;
`

export const LoginButtonGroup = styled(LoginGroup)`
    justify-content: center;
    align-items:center;
    margin-top: 20px;
`

export const LoginButton = styled.button`
    height: 35px;
    font-size: 0.9rem;
    cursor:pointer;
    font-weight: 600;
    width: 50%;
    border: none;
    border-radius: 2px;
    background-color: #f9e036;
    box-shadow: 0px 1px 4px 0px #f9e036;
    transition: 0.5s all;
    text-transform: uppercase;

    &:hover{
        transform: scale(1.04)
    }
`