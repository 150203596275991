import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const Counter = styled.div`
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 40px;
    right: 40px;
    background: #2b2b2b;
    border-radius: 50%;
    border: 4px solid #181818;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 999;
    font-weight: 700;
`


export const AsideButton = styled.div`
    display: flex;
    position: absolute;
    bottom: 30px;
    left:40px;
    width: 100%;

    @media screen and (max-width: 768px)  {
       bottom: 7%;
       left: initial;
       justify-content: center;
    }
`


export const BackButton = styled.a`
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #222;
    height: 35px;
    padding: 0px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border: 2px solid #222222b8;
    gap: 10px;
    transition: 0.5s all;
    text-decoration:none;

    @media screen and (max-width: 768px)  {
        padding: 0px 20px;
        font-size: 14px;
        height: 30px;
    }


    :hover{
        transform: scale(1.05);
    }

    img{
        width: 22px;
        height: 22px;
    }
`
