export const getToken = () => {
    let token = sessionStorage.getItem('token');
    if(token == "kIKY^583w^JSuQ^5qHRo8j5xUs4L1^hanZ!!xS#k0y2jCWTzRn"){
        return token
    }
}

export const setToken = () => {
    sessionStorage.setItem('token', 'kIKY^583w^JSuQ^5qHRo8j5xUs4L1^hanZ!!xS#k0y2jCWTzRn');
}

export const clearToken = () => {
    sessionStorage.removeItem('token');
}