import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "../src/pages/Home"
import Login from "../src/pages/Login"
import Report from "../src/pages/Report"

function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/report" element={<Report />} />
          <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
