import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearToken } from '../../utils'
import {
    HeaderContainer, HeaderLogo, HeaderInfo,
    HeaderLink, HeaderImage, InfoBox,
    InfoText, InfoIcon, LogoText, InfoTextArrows, InfoIconArrow,
    InfoTextLogout
} from './styles'

import moment from "moment"
import 'moment/locale/pt-br';
moment.locale('pt-BR');

export default function Header() {
    const navigate = useNavigate();

    const [hour, setHour] = useState(moment().format("HH:mm:ss"))

    useEffect(() => {
        setTimeout(() => {
            setHour(moment().format("HH:mm:ss"))
        }, 1000);
    
    })
   
    const handleLogout = () => {
        clearToken()
        navigate("/")
    }
    
    return (
        <HeaderContainer>
            <HeaderLogo>
                <HeaderLink href="/">
                    <HeaderImage src="/images/logo_am4.png" />
                    <LogoText>LTS - Monitor</LogoText>
                </HeaderLink>
            </HeaderLogo>
            <HeaderInfo>
                <InfoBox>
                    <InfoIcon src="/images/clock.png" />
                    <InfoText>{moment().format("DD [de] MMMM [de] YYYY")} • {hour}</InfoText>

                </InfoBox>
                <InfoTextLogout onClick={() => handleLogout()}>Sair</InfoTextLogout>
            </HeaderInfo>


        </HeaderContainer>
    )
}

