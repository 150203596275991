import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { setToken, getToken } from '../../utils'

import {
    LoginContainer, LoginBox,
    LoginLabel, LoginInput,
    LoginButton, LoginTitle,
    LoginGroup, LoginImage,
    LoginButtonGroup, LoginError
} from './styles'

export default function Login() {
    const navigate = useNavigate();
    let token = getToken()

    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)



    useEffect(() => {if (token) { navigate('/dashboard') }}, [token])

    const handleLogin = () => {
        setError(false)
        if((user == "admin" && password == "3Ket5r^r&DAhZ8") ||
           (user == "lts" && password=="xgi9oW2OL15YQ!") 
        ){
            setToken()
            navigate('/dashboard')
        }else{
            setError(true)
        }
    }

    return (
        <>
            <LoginContainer>
                <LoginBox>
                    <LoginImage src="/images/logo_am4.png" />
                    <LoginTitle>LTS Monitor</LoginTitle>
                    <LoginGroup>
                        <LoginLabel>Usuário</LoginLabel>
                        <LoginInput onChange={(ev) => setUser(ev.target.value)} />
                    </LoginGroup>

                    <LoginGroup>
                        <LoginLabel>Senha</LoginLabel>
                        <LoginInput type="password" onChange={(ev) => setPassword(ev.target.value)} />
                    </LoginGroup>
                    
                    {
                        error && <LoginError>Usuário ou Senha inválido</LoginError>
                    }

                    <LoginButtonGroup>
                        <LoginButton onClick={() => handleLogin()} >Entrar</LoginButton>
                    </LoginButtonGroup>
                </LoginBox>
            </LoginContainer>

        </>

    )
}
